import clsx from 'clsx'
import {ChangeEventHandler, FC, FocusEventHandler, HTMLInputTypeAttribute} from 'react'

type Props = {
  label?: string | undefined
  value?: string | number | readonly string[] | undefined
  onChange?: ChangeEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  placeholder?: string | undefined
  name?: string | undefined
  type?: HTMLInputTypeAttribute | undefined
  isValid?: boolean
  errorMessage?: string
  readOnly?: boolean
  required?: boolean
  disabled?: boolean
  className?: string
  defaultValue?: string
}

const InputGroup: FC<Props> = ({
  label,
  value,
  onChange,
  placeholder,
  name,
  type,
  onBlur,
  isValid,
  errorMessage,
  readOnly,
  required = true,
  disabled,
  className,
  defaultValue,
}) => {
  return (
    <>
      {label && <label className={`${required ? 'required' : ''} form-label`}>{label}</label>}
      <input
        type={type}
        name={name}
        className={clsx('form-control mb-2', className)}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
        defaultValue={defaultValue}
      />
      {!isValid && errorMessage && <span className='text-danger mt-2'>{errorMessage}</span>}
    </>
  )
}

const InputGroupFilled: FC<Props> = ({
  label,
  value,
  onChange,
  placeholder,
  name,
  type,
  onBlur,
  isValid,
  errorMessage,
  readOnly,
  required = true,
  disabled,
}) => {
  return (
    <>
      {label && <label className={`${required ? 'required' : ''} form-label`}>{label}</label>}
      <input
        type={type}
        name={name}
        className='form-control form-control-solid'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        disabled={disabled}
      />
      {!isValid && errorMessage && <span className='text-danger mt-2'>{errorMessage}</span>}
    </>
  )
}

export {InputGroup, InputGroupFilled}
